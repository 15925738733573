import { Guid } from './guid';

/**
 * This module contains constant, type and type predicate for the pubSub.service.ts
 */
const systemEvents = [
  'READY_FOR_SIGNALR',
  'SIGNALR_CONNECTED',
  'SIGNALR_CONNECT_FAILED'
] as const;

const signalrEvents = [
  //signalR events
  'SIGNALR_WORKFLOW_UNPUBLISHED_CHANGES',
  'SIGNALR_WORKFLOW_PUBLISHED',
  'SIGNALR_WORKFLOW_UNPUBLISHED',
  'SIGNALR_FLOW_MODEL_STATUS_CHANGED',
  'SIGNALR_NEW_FLOW_MODEL_SAVED',
  'SIGNALR_USER_PROFILE_UPDATED', //currently only used when avatar deleted / updated / added server side
  'SIGNALR_USER_NOTIFICATION_COUNT_CHANGED',
  'SIGNALR_RUNNER_NEW_FLOW_COMMENT_COUNT',
  'SIGNALR_RUNNER_NEW_FLOW_COMMENT',
  'SIGNALR_SETUP_CATEGORY_DELETED', //used to update client side lists
  'SIGNALR_SETUP_FLOW_MODEL_DELETED', //used to update client side lists
  'SIGNALR_RUNNER_WITHDRAW_FLOW',
  'SIGNALR_RUNNER_COMPLETE_STEP',
  'SIGNALR_RUNNER_REASSIGN_STEP',
  'SIGNALR_RUNNER_START_FLOW',
  'SIGNALR_WORKFLOW_NAME_CHANGED',
  'SIGNALR_ACTOR_DELETED',
  'SIGNALR_RUNNER_USER_TEAM_UPDATED',
  'SIGNALR_RUNNER_STEP_WEBHOOK_UPDATED',

  'SIGNALR_RUNNER_STEP_TASK_CANCELLED',
  'SIGNALR_RUNNER_NEW_STEP_TASK_COMMENT', // When a new step task comment is added in the API.
  'SIGNALR_RUNNER_STEP_TASK_CREATED', // When a step task is created in the API.
  'SIGNALR_RUNNER_STEP_TASK_UPDATED', // When a step task is updated in the API.

  'SIGNALR_RUNNER_STEP_INTEGRATION_PROCESSING_UPDATED'
] as const;
const noneSystemEvents = [
  // include all signalR events
  ...signalrEvents,

  //Modeler events
  'CARD_DESIGNER_CLOSE_EDITORS',
  'CARD_DESIGNER_OPEN_EDITORS',
  'CARD_DESIGNER_DRAG_DROP',

  'CARD_UPDATED',

  'DIAGRAM_LINK_INSERTED',
  'DIAGRAM_LINK_RELINKED',
  'DIAGRAM_LINK_DELETED',
  'DIAGRAM_PARTS_DELETED',
  'DIAGRAM_NODE_SELECTED',
  'DIAGRAM_POSITION_CHANGED',
  'DIAGRAM_CLICKED',
  'DIAGRAM_NODE_CLICKED',
  'DIAGRAM_ELEMENT_DROPPED',
  'TASK_PANEL_OPENCLOSE',
  'GATEWAY_FIELD_CHANGED',
  'WORKFLOW_NODE_CHANGED',
  'STEP_TYPE_CHANGED',
  'GATEWAY_TYPE_CHANGED',
  'GATEWAY_DEFAULT_GATE_CHANGED',
  'DIAGRAM_MODIFIED',
  'DIAGRAM_LOADED',
  'SELECTED_NODE_CHANGED',
  'MODELER_FILE_EVENT',
  'MODELER_ACTION_EVENT',
  'WORKFLOW_VALID', //the workflow has no errors (after validation) //TODO consider combinig wiht blow = WORKFLOW_VALIDATED
  'WORKFLOW_INVALID', //the workflow has one or more errors (after validation)
  'WORKFLOW_UPDATED', //the worklow or the associated schema has changed (new / imported / saved)
  'WORKFLOW_LOADED', //a workflow has been loaded
  'STEP_REF_SEQUENCE_CHANGED', // when step ref sequence has changed
  'STEP_ACTOR_CHANGED', // when actor is assigned to a step
  'NOTIFY_INITIATOR_CHANGED', //when the user toggles => Notify Initiator when this step is completed?
  'PUBLICFORM_SETTING_CHANGED', //when the user toggles => Make this a public form
  'WORKFLOW_SAVE_COMPLETED',
  'WORKFLOW_PUBLISHED',
  'WORKFLOW_UNPUBLISHED',
  'WORKFLOW_CLONED',
  'UPDATE_EDITING',
  'WORKFLOW_DESIGNER_FORM_FIELDS_CHANGED',
  'WORKFLOW_DESIGNER_FORM_FIELDS_ADDED',
  'FORM_FIELD_NAME_OR_STEP_NAME_CHANGED',
  'FORM_FIELD_NAME_OR_STEP_NAME_CHANGE_COMPLETE',
  'ADD_NEW_SWIM_LANE',
  'REMOVE_SWIM_LANE',
  'STEPRULE_SETTING_CHANGED',
  'WORKFLOW_PUBLISH_PROCESSED',

  'IMAGEUPLOAD_UPLOAD_STARTED',
  'IMAGEUPLOAD_UPLOAD_COMPLETED',

  //RUNNER EVENTS
  'RUNNER_RESIZE_GRID',
  'STEP_NEW_NUDGE', //when step waiting on actor get nudged, thus need notify area which display step nudge history
  'FILEUPLOAD_FILE_ERROR', //a file is required, but not present on form
  'FILEUPLOAD_FILE_VALID', //a file is required and present on form (or not required)
  'FILEUPLOAD_FILE_READY',
  'FILEUPLOAD_UPLOAD_STARTED',
  'FILEUPLOAD_UPLOAD_COMPLETED',
  'FILEUPLOAD_REMOVAL_STARTED',
  'FILEUPLOAD_REMOVAL_COMPLETED',
  'FILEUPLOAD_UPLOAD_FAILED', //file upload has failed for some reason
  'FORM_TABLE_VALID',
  'FORM_TABLE_INVALID',

  //GENERAL EVENTS
  'WINDOW_BLUR',
  'USER_LOGGED_IN',
  'USER_LOGGED_OUT',
  'FILE_SELECTED',
  'CLIENT_USER_PROFILE_UPDATED', //user's profile &/Or avatar is updated

  //INTERCOM EVENTS
  'INTERCOM_UNREAD_MESSAGE_COUNT_CHANGED',

  // When integration settings has been updated, (for ex. to show/hide integration icon)
  'STEP_INTEGRATION_SETTING_CHANGED',

  'STEP_TASK_ENABLED_CHANGED', // When step tasks have been enabled/disabled on a node in Modeler.
  'STEP_TASK_CREATED', // When a step task is created
  'STEP_TASK_UPDATED', // When a step task is updated
  'STEP_TASK_CANCELLED', // When a step task is canceled

  'WORKFLOW_FIELDS_UPDATED', // When runner step fields are updated.

  'KENDO_FACADE_UPDATE_COLLECTION', // When collection bound to kendo-combobox-facade needs reloading.

  //V2(REACT) EVENTS
  'PROCESSMAPV2_CLICKED',
  'PROCESSMAPV2_LOADED',
  'PROCESSMAPVIEWV2_LOADED',

  'CATEGORY_WITH_SUBCATEGORY_ADDED',
  'BACKTOFLOWS_IMIN',
  'NAVIGATE_BACK',
  'CLEAR_CAPTCHA'
] as const;

export const allPubSubEvents = [...systemEvents, ...noneSystemEvents] as const; // all events
export type PubSubEvent = (typeof allPubSubEvents)[number];
export type SystemEvent = (typeof systemEvents)[number];
export type SignalrEvent = (typeof signalrEvents)[number];
export type Callback = (
  event: PubSubEvent,
  data: unknown,
  params?: unknown
) => void;
export type Subscriber = {
  id: string;
  callback: Callback;
  param?: unknown;
};
export type PendingEvent = { event: PubSubEvent; data: unknown };
export type PubSubService = {
  subscriptions: Record<PubSubEvent, Subscriber[]> | Record<string, never>;
  subscribe: (
    event: PubSubEvent,
    callback: Callback,
    subscriberId: string,
    param?: unknown
  ) => void;
  publish: (event: PubSubEvent, data?: unknown, isImmediate?: boolean) => void;
  unsubscribeAll: (subscriberId: string) => void;
};

// type predicate to check if an event is a system event
export function isSystemEvent(event: PubSubEvent): event is SystemEvent {
  return systemEvents.findIndex((e) => e === event) > -1;
}

// type predicate to check if an event is signalr event
export function isSignalrEvent(event: PubSubEvent): event is SignalrEvent {
  return signalrEvents.findIndex((e) => e === event) > -1;
}

export interface IEventFileUploadResult {
  fileControlId: string;
  flowInstanceId: Guid;
}
